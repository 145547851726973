<template>
  <n-form-item
    class="nd-form-item"
    :class="debouncedValidationStatus !== 'error' && '-mb-6'"
    :feedback="debouncedFeedback"
    :validation-status="debouncedValidationStatus"
    v-bind="$attrs"
  >
    <template v-if="$slots.label" #label>
      <slot name="label"></slot>
    </template>
    <slot name="default"></slot>
  </n-form-item>
</template>

<script setup lang="ts">
import { NFormItem } from 'naive-ui'

type ValidationStatus = 'success' | 'warning' | 'error' | undefined

const props = withDefaults(
  defineProps<{
    feedback?: string
    validationStatus?: ValidationStatus
    debounce?: number
  }>(),
  {
    feedback: '',
    validationStatus: undefined,
    debounce: 0,
  }
)

const debouncedFeedback = ref('')
const debouncedValidationStatus = computed<ValidationStatus>(() => (debouncedFeedback.value ? 'error' : undefined))
const feedback = computed(() => props.feedback)

watchDebounced(
  feedback,
  (newValue: string) => {
    debouncedFeedback.value = newValue
  },
  { debounce: props.debounce }
)
</script>

<style lang="scss" scoped>
.nd-form-item {
  :deep(.n-form-item-feedback-wrapper) {
    @apply pl-0;
    .n-form-item-feedback {
      @apply text-sm;
    }
  }
}
</style>
