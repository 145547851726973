import * as Yup from 'yup'
import { phoneHelpers } from '@/utils/phone'
/**
 * 文件：
 * 使用時只需要在規則後面加上其他 yup function 即可
 * 例如: rule.email().required()
 * 或是用 concat 串接已經寫好的 schema，例如：
 * rule.onlyLetterAndNumber().concat(rule.wordLimit())
 * rule.required().concat(rule.email())
 * 預設所有選項都是必填，如果要選填在後面加上 notRequired() 即可，例如：
 * rule.required().notRequired()
 */
const REQUIRED_MESSAGE_KEY = 'all_error_fill_in_incorrect'

const FUNNOW_PASSWORD_REGEX = /^(?=.*[a-zA-Z0-9]).{6,}$/
const INVALID_CHAR_REGEX = /^[^`~!@#$%^&*()_+={}[\]|\\:;“’<,>.?๐฿]*$/u
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const useFieldRules = () => {
  const { t } = useI18n()
  const requiredMessage = t(REQUIRED_MESSAGE_KEY)

  return {
    required: () => Yup.string().trim().required(requiredMessage),
    requiredArray: () => Yup.array().min(1, requiredMessage),
    requiredQuantity: () =>
      Yup.array().test('required quantity', requiredMessage, items => (items as any[]).reduce((acc, c) => acc + c?.value, 0) > 0),
    onlyLetterAndNumber: () => Yup.string().trim().matches(INVALID_CHAR_REGEX, t('name_characters_error')).required(requiredMessage),
    wordLimit: () =>
      Yup.string()
        .trim()
        .test('len', t('nickname_length_constraint_msg'), (value = '') => {
          // db 限制 128 bytes
          const wordsBytesLength = new TextEncoder().encode(value).length

          const adjustedLength = value.split('').reduce((length: number, char: string) => {
            const charCode = char.charCodeAt(0)
            if (
              (charCode >= 0x4e00 && charCode <= 0x9fff) || // 中文
              (charCode >= 0x3000 && charCode <= 0x30ff) || // 日文
              (charCode >= 0xff01 && charCode <= 0xff5e) // 全形英文、數字、符號
            ) {
              return length + 2
            } else {
              return length + 1
            }
          }, 0)

          return wordsBytesLength <= 128 && adjustedLength <= 32
        })
        .required(requiredMessage),
    email: () => Yup.string().matches(EMAIL_REGEX, t('all_error_msg_input_valid_email')).required(requiredMessage),
    phone: () =>
      Yup.string()
        .trim()
        .test('phone', t('cellphone_format_error'), value => {
          if (!value) return true
          const [callingCode, phoneNumber] = value.split(' ')
          if (!callingCode || !phoneNumber) return true
          return phoneHelpers.isValidMobileNumber(phoneNumber, callingCode)
        }),
    requiredPhone: () =>
      Yup.string().test('required phone', requiredMessage, value => {
        if (!value) return false
        const [callingCode, phoneNumber] = value.split(' ')
        if (!callingCode || !phoneNumber) return false
        return true
      }),
    password: () => Yup.string().matches(FUNNOW_PASSWORD_REGEX, t('member_password_insufficient')).required(requiredMessage),
    confirmPassword: ({ refKey } = { refKey: 'password' }) =>
      Yup.string()
        .oneOf([Yup.ref(refKey)], t('new_pwd_not_match_hint'))
        .required(t('member_password_input_again')),
  }
}
