// ref. bossnow2 /helpers/phone.ts
import googleLibphonenumber, { PhoneNumberType } from 'google-libphonenumber'
// ref. https://github.com/ruimarinho/google-libphonenumber

function createGoogleLibphonenumber() {
  const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance()
  const deviceRegionCode = process.client ? window.navigator.language.toLocaleUpperCase() : 'TW'
  const defaultRegionCode = phoneUtil.getSupportedRegions().find(code => deviceRegionCode.includes(code)) || 'TW'
  const defaultCallingCode = '+886'
  /**
   * 是否可能是電話號碼
   * returns true if the number is either a possible fully-qualified number (containing the area code and country code),
   * or if the number could be a possible local number (with a country code, but missing an area code).
   */
  function isPossibleNumber(phone: string, regionCode: string = defaultRegionCode) {
    try {
      if (!phone) {
        return false
      }
      const number = phoneUtil.parseAndKeepRawInput(phone, regionCode)
      return phoneUtil.isPossibleNumber(number)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  /**
   * 是否為合法的號碼
   * tests whether a phone number matches a valid pattern.
   */
  function isValidNumber(phone: string, regionCode: string = defaultRegionCode) {
    try {
      if (!phone) {
        return false
      }
      const number = phoneUtil.parseAndKeepRawInput(phone, regionCode)
      return phoneUtil.isValidNumber(number)
    } catch (error) {
      console.error(error)
      return false
    }
  }

  /**
   * 是否為合法的「手機」號碼
   */
  function isValidMobileNumber(phone: string, callingCode = defaultCallingCode) {
    try {
      if (!phone) {
        return false
      }
      const regionCode = getRegionCodeForCountryCode(Number(callingCode.replace('+', '')))
      const number = phoneUtil.parseAndKeepRawInput(phone, regionCode)
      return (
        phoneUtil.isValidNumber(number) &&
        [PhoneNumberType.MOBILE, PhoneNumberType.FIXED_LINE_OR_MOBILE, PhoneNumberType.PERSONAL_NUMBER].includes(phoneUtil.getNumberType(number))
      )
    } catch (error) {
      console.error(error)
      return false
    }
  }

  /**
   * 取得國際格式電話號碼
   */
  function toInternationalFormat(phone: string, regionCode: string = defaultRegionCode) {
    try {
      if (!phone) {
        return ''
      }
      const number = phoneUtil.parseAndKeepRawInput(phone, regionCode)
      return `+${number.getCountryCode()} ${number.getNationalNumber()}`
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  /**
   * 透過區碼（ex: 886）取得國家代碼（ex: TW）
   */
  function getRegionCodeForCountryCode(callingCode: number) {
    try {
      if (!callingCode) {
        return ''
      }
      return phoneUtil.getRegionCodeForCountryCode(callingCode)
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  /**
   * 透過國家代碼（ex: TW）取得區碼（ex: 886）
   */
  function getCountryCodeForRegion(regionCode: string) {
    try {
      if (!regionCode) {
        return ''
      }
      return phoneUtil.getCountryCodeForRegion(regionCode)
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  function getRegionCodeFromCountryOrRegion({ regionCode, callingCode }: { regionCode?: string; callingCode?: string }) {
    if (regionCode) {
      return regionCode
    }
    if (callingCode) {
      return getRegionCodeForCountryCode(Number(callingCode.replace('+', '')))
    }
    return defaultRegionCode
  }

  return {
    isPossibleNumber,
    isValidNumber,
    isValidMobileNumber,
    toInternationalFormat,
    getRegionCodeForCountryCode,
    getRegionCodeFromCountryOrRegion,
    getCountryCodeForRegion,
    getSupportedRegions: phoneUtil.getSupportedRegions,
  }
}

export const phoneHelpers = createGoogleLibphonenumber()
